<template>
    <div class="report-content">
        <el-scrollbar class="main-content">
            <div class="top-content">
                <el-breadcrumb class="top-breadcrumb" separator-class="el-icon-arrow-right">
                    <el-breadcrumb-item>报告管理</el-breadcrumb-item>
                    <el-breadcrumb-item>查看详情</el-breadcrumb-item>
                </el-breadcrumb>
                <div class="btn-box">
                    <el-button type="primary" plain @click="batchPrint">批量打印</el-button>
                    <el-button type="primary" @click="goBack">返回</el-button>
                </div>
            </div>
            <div class="search-box">
                <el-input placeholder="请输入搜索内容" style="width: 300px;" v-model="searchValue">
                    <el-button slot="append" icon="el-icon-search" @click="searchBtn"></el-button>
                </el-input>
            </div>
            <el-table class="customTable" :data="studentList" height="1%" size="medium"
                      :header-cell-style="{fontWeight: 'normal', height: '60px', color: '#666666', background: '#F6F6F6',fontSize: '16px'}"
                      :cell-style="{fontSize: '16px',color: '#333333',height: '70px'}"
                      @selection-change="handleSelectionChange">
                <el-table-column type="selection" width="100"></el-table-column>
                <el-table-column prop="student_name" label="学生名称" align="center" width="200"></el-table-column>
                <el-table-column prop="class_name" label="班级" align="center"></el-table-column>
                <el-table-column prop="exam_name" label="考试名称" align="center"></el-table-column>
                <el-table-column align="center" label="操作">
                    <template slot-scope="scope">
                        <el-link type="success" :underline="false" @click="viewDetail(scope.row)">查看报告</el-link>
                    </template>
                </el-table-column>
            </el-table>
            <pagination class="pages-center" :total="total" @current-change="handleCurrentChange"/>
        </el-scrollbar>
    </div>
</template>

<script>
    import pagination from "../../../components/pagination";
    import {reportManagementStuList, examSeeStuReport} from '@/utils/apis';

    export default {
        name: "ReportDetail",
        components: {
            pagination
        },
        data() {
            return {
                searchValue: '',
                studentList: [
                    {
                        exam_id: 56,
                        name: "李思思",
                        exam_name: '考试名称考试名称考试名称考试名称考试名称',
                        className: '电商一班'
                    },
                ],
                total: 0,
                currentPageNum: 1,
                exam_id: this.$route.query.id,
                multipleSelection: []
            }
        },
        created() {
            this.getStudentList();
        },
        methods: {
            getStudentList() {
                let param = {
                    page: this.currentPageNum,
                    paging: 1,
                    pageSize: 10,
                    exam_id: this.exam_id
                }

                if (this.searchValue != '') {
                    param.search = this.searchValue
                }
                reportManagementStuList(param).then(res => {
                    this.studentList = res.data.data;
                    this.total = res.data.total;
                }).catch(err => {
                    console.error(err)
                })
            },
            batchPrint() {
                this.$message.warning('敬请期待！')
                // let studentIds = ''
                // studentIds = this.multipleSelection.join('#')
                // if (studentIds !== '') {
                //     let param = {
                //         exam_id: this.exam_id,
                //         student_id: studentIds
                //     }
                //     examSeeStuReport(param).then(res => {
                //         this.studentInfo = res.data;
                //         this.isInfo = true;
                //     }).catch(err => {
                //         console.error(err)
                //     })
                // }
            },
            goBack() {
                this.$router.push('/school/report/index')
            },
            handleSelectionChange(val) {
                let arr = [];
                val.forEach(item => {
                    arr.push(item.student_id)
                })
                this.multipleSelection = arr;
            },
            searchBtn() {
                // if (this.searchValue != '') {
                this.getStudentList()
                // }
            },
            viewDetail(row) {
                this.$router.push({
                    path: '/school/report/singleReport',
                    query: {
                        id: this.exam_id,
                        stu_id: row.student_id
                    }
                })
            },
            handleCurrentChange(val) {
                this.currentPageNum = val;
                this.getStudentList();
            }

        }
    }
</script>

<style scoped lang="scss">
    .report-content {
        padding: 0 20px;
        height: 100%;
        display: flex;
        flex-direction: column;

        .main-content {
            flex: 1;
            height: 100%;
        }

        ::v-deep .el-scrollbar__wrap {
            overflow-x: hidden;
        }

        ::v-deep .el-scrollbar__view {
            padding: 0 20px;
            height: calc(100% - 20px);
            display: flex;
            flex-direction: column;
        }

        .top-content {
            display: flex;
            justify-content: space-between;
            align-items: center;
            position: relative;

            &:after {
                position: absolute;
                content: '';
                background: #F1F5FF;
                height: 2px;
                width: 100%;
                bottom: -16px;
            }
        }

        .search-box {
            padding-top: 32px;

            ::v-deep .el-input {
                .el-input-group__append {
                    padding-right: 10px;
                    box-sizing: border-box;
                    height: 40px;
                    //background: #2DC079;
                    color: #fff;
                    font-size: 16px;

                    .el-button {
                        height: 40px;
                        border-radius: 0 4px 4px 0;
                        padding: 0 8px;
                        background: #2DC079;

                        &:hover {
                            background: #2DC079;
                        }
                    }
                }
            }
        }

        .customTable {
            width: 100%;
            flex: 1;
            border: 1px solid #EEEEEE;
            margin-top: 16px;
        }
    }
</style>